import React from 'react';
import Link from "../Link";
import './style.less';

export default class WorkFilter extends React.Component {
    render() {
        return (
            <div className="filter work">
                <h4 className="filter-title">Filter work by</h4>
                <ul className="filter-list">
                    <li>
                        <Link data-active={this.props.active === 'all'} to="/our-work/">All</Link>
                    </li>
                    <li>
                        <Link data-active={this.props.active === 'digital-marketing'} to="/our-work/digital-marketing/">Digital marketing</Link>
                    </li>
                    <li>
                        <Link data-active={this.props.active === 'content'} to="/our-work/content/">Content</Link>
                    </li>
                    <li>
                        <Link data-active={this.props.active === 'design'} to="/our-work/design/">Design</Link>
                    </li>
                    <li>
                        <Link data-active={this.props.active === 'development'} to="/our-work/development/">Development</Link>
                    </li>
                    <li>
                        <Link data-active={this.props.active === 'email'} to="/our-work/email/">Email</Link>
                    </li>
                    <li>
                        <Link data-active={this.props.active === 'social'} to="/our-work/social/">Social</Link>
                    </li>
                </ul>
            </div>
        );
    }
}
