import React from 'react';
import './style.less';
import Img from 'gatsby-image'
import Link from "../Link";
import {Col, Row} from "antd";

export default class CaseStudyListItem extends React.Component {

    render() {
        return <div className="case-study-item" style={{backgroundColor: this.props.caseStudy.acf.project_colour}}>
            <Row>
                <Col xs={{span:24}} md={{span:14}}>
                    <h2 className="h3"><a href={"/our-work/"+this.props.caseStudy.slug}>{this.props.caseStudy.acf.project_strapline}</a></h2>
                </Col>
                <Col xs={{span: 24}} md={{span:10}}>
                    <p className="services">{this.props.caseStudy.acf.services && this.props.caseStudy.acf.services.map((service) => {
                        return <span key={'csli-key-'+service.value}>{service.label}</span>
                    })}</p>
                    <a href={"/our-work/"+this.props.caseStudy.slug} className="project-image">
                        <Img fluid={this.props.caseStudy.acf.project_image.localFile.childImageSharp.fluid} />
                    </a>
                    <Link to={`/our-work/${this.props.caseStudy.slug}/`} className="more">Read more</Link>
                </Col>
            </Row>
        </div>
    }
}
