import React, { Component } from "react"
import './CaseStudyList.less';
import {graphql} from "gatsby";
import Wrapper from '../components/Wrapper.js';
import { Pagination } from 'antd';
import { navigate } from '@reach/router';
import PageTitle from "../modules/PageTitle";
import CaseStudyListItem from "../components/CaseStudyListItem";
import WorkFilter from "../components/WorkFilter";

class CaseStudyListByService extends Component {

    constructor(props) {
        super(props);
        this.gotoPage = this.gotoPage.bind(this);
    }

    gotoPage(page){
        if(page===1){
            navigate(`/our-work/${this.props.pageContext.service}`);
        } else {
            navigate(`/our-work/${this.props.pageContext.service}/page/${page}`);
        }
    }

    render() {
        const caseStudies = this.props.data.allWordpressWpCaseStudy.edges;
        return (
            <Wrapper
                title="Our work – brands becoming their digital best"
                description="Take a look at the digital marketing campaigns we're proud of, from digital strategy workshops through to scroll-stopping social campaigns and brand-new websites."
                canonical="our-work"
            >
                <PageTitle title="Our work is your problem solved" />
                <WorkFilter active={this.props.pageContext.service}/>
                <div className="case-study-listing">
                    {caseStudies && caseStudies.map(({node}) => {
                        return (
                            <CaseStudyListItem key={node.id} caseStudy={node} />
                        )
                    })}
                </div>
                <Pagination
                    current={this.props.pageContext.current}
                    total={this.props.pageContext.numPosts}
                    pageSize={this.props.pageContext.limit}
                    onChange={this.gotoPage}
                />
            </Wrapper>
        )
    }
}

export const caseStudyListByServiceQuery = graphql`
  query caseStudyListByServiceQuery($skip: Int!, $limit: Int!, $service: String!) {
    allWordpressWpCaseStudy(limit: $limit, sort: { order: DESC, fields: [date] }, skip: $skip,
    filter: {
     acf: {services:{elemMatch: {value: {eq: $service}}}}
    }
    ){
      edges {
        node {
        wordpress_id
        id
        slug
        date     
        acf {
          project_strapline
          project_colour
          project_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 400) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }          
          services {
            value
            label
          }
        }
      }
    }
  }
 }
`;

export default CaseStudyListByService


